.video-opener {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    padding: 1em 3em;
    border-radius: .5rem;
    text-align: left;
    font-family: Inter,system-ui,Avenir,Helvetica,Arial,sans-serif;
    font-weight: 400;
    background: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='white' stroke-width='0' viewBox='0 0 20 20' aria-hidden='true' class='action-icon' height='1.2em' width='1.2em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.3 2.84A1.5 1.5 0 0 0 4 4.11v11.78a1.5 1.5 0 0 0 2.3 1.27l9.344-5.891a1.5 1.5 0 0 0 0-2.538L6.3 2.841Z'%3E%3C/path%3E%3C/svg%3E") no-repeat 1.25em 50% #00000040;
}

.video-opener:hover {
    background-color: #000;
}