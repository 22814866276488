
@keyframes countdown {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

.countdown-line {
  animation: countdown 300s linear infinite;
}