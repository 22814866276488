div.list-player *, :before, :after {
  --lp-ring-offset-shadow: 0 0 #0000;
  --lp-ring-shadow: 0 0 #0000;

  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif,system-ui,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
  font-feature-settings: normal;
  font-variation-settings: normal;

  --lp-gap-1: .25rem;
  --lp-gap-2: .5rem;
  --lp-gap-var-1: 3.5%;
  --lp-gap-var-2: 6%;
  --lp-pad-1: .3rem;
  --lp-pad-2: .6rem;
  --lp-text-op-2: .6;
  --lp-text-color: #ffffffde;

  font-family: Inter,system-ui,Avenir,Helvetica,Arial,sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color-scheme: light dark;
  color: var(--lp-text-color);
  font-synthesis: none;
  text-rendering: optimizeLegibility;

  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

/* SCROLLBARS ----------------- */
/* Firefox */
div.list-player * {
  scrollbar-width: thin;
  scrollbar-color: #ffffff44 transparent;
}

/* Chrome, Edge, and Safari */
div.list-player *::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

div.list-player *::-webkit-scrollbar-track {
  background: transparent;
}

div.list-player *::-webkit-scrollbar-thumb {
  background-color: #ffffff44;
  border-radius: 10px;
  border: 0px solid #ffffff44;
}

div.list-player *::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff88;
}

@media (prefers-color-scheme: dark) {
  div.list-player *::-webkit-scrollbar-thumb {
    background-color: #00000028;
    border-radius: 10px;
    border: 0px solid #00000028;
  }

  div.list-player *::-webkit-scrollbar-thumb:hover {
    background-color: #00000055;
  }

  /* Firefox */
  div.list-player * {
    scrollbar-width: thin;
    scrollbar-color: #00000028 transparent;
  }
}

.list-player h1, .list-player h2, .list-player h3, .list-player h4, .list-player h5, .list-player h6 {
  font-size: inherit;
  font-weight: inherit;
}

.list-player blockquote, .list-player dl, .list-player dd, .list-player h1, .list-player h2, .list-player h3, .list-player h4, .list-player h5, .list-player h6, .list-player hr, .list-player figure, .list-player p, .list-player pre {
  margin: 0;
}

.list-player button, .list-player input, .list-player optgroup, .list-player select, .list-player textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.list-player button, .list-player select {
  text-transform: none;
}

.list-player button, .list-player [type=button], .list-player [type=reset], .list-player [type=submit] {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.list-player button, .list-player [role=button] {
  cursor: pointer;
}

.list-player img, .list-player svg, .list-player video, .list-player canvas, .list-player audio, .list-player iframe, .list-player embed, .list-player object {
  display: block;
  vertical-align: middle;
}

.list-player img, .list-player video {
  max-width: 100%;
  height: auto;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.list-player a {
  color: var(--lp-text-color);
  text-decoration: none;
}

.list-player a:hover {
  text-decoration: underline;
  text-decoration-style: solid;
  text-underline-offset: 2px;
}

/* LISTPLAYER ----------------- */
.list-player {
  display: flex;
  flex-direction: column;
  gap: 0;
  width: inherit;
  min-width: 315px;
  max-width: 100%;
  min-height: 92px;
  max-height: 100%;
  overflow: hidden;
  background-color: rgb(0 0 0 / 0.25);
  border-radius: 0.5rem;
  transition-property: max-height, max-width;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

.list-player .list-header {
  --lp-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  box-shadow: var(--lp-ring-offset-shadow, 0 0 #0000), var(--lp-ring-shadow, 0 0 #0000), var(--lp-shadow);
  position: relative;
  width: 100%;
  min-height: 5.75rem;
  background-color: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  transition-property: max-height, height;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

.list-player .list-body {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  gap: 0;
  overflow-y: auto;
  padding-top: 0.65rem;
  padding-bottom: 0.3rem;
  margin-top: 0;
  margin-bottom: var(--lp-pad-1);
  margin-right: var(--lp-pad-1);
  margin-left: var(--lp-pad-2);
  container-type: inline-size;
  container-name: list;
}

.list-player .list-body:first-child {
  padding-top: 0.3rem;
  margin-top: var(--lp-pad-1);
}

.list-player div.track {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  width: calc(100% - 0.3rem);
  padding-top: 5px;
  padding-bottom: 7px;
  margin-right: 0.3rem;
  border-radius: 0.4rem;
}

.list-player div.track:hover, .list-player div.track.selected {
  background-color: rgb(0 0 0 / 0.05);
}

.list-player div.track:hover .list-number {
  display: none;
}

.list-player div.track:hover .action-icon, .list-player div.track-number .action-icon.sel  {
  display: unset;
}

.list-player .action-icon:hover {
  cursor: pointer;
}

.list-player span.number {
  font-size: large;
  font-weight: 600;
  opacity: var(--lp-text-op-2);
}

.list-player div.track-number {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px/*has to be set to 27px so that this div doesnt change height when the action-icon is displayed */;
  min-width: 2rem;
}

.list-player div.track-number .action-icon {
  display: none;
  margin-left: 4px;
  width: 1.25rem;
  height: 1.25rem;
}

.list-player .track span.cdot, .list-player .lt-info-card span.cdot {
  font-weight: bold;
  margin: 1px;
}

.list-player .list-number {
  display: unset;
}

.list-player .info-cont {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 1;
  gap: 2rem;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 98%, rgba(0, 0, 0, 0) 101%);
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 98%, rgba(0, 0, 0, 0) 101%);
  container-name: info;
  container-type: inline-size;
}

.list-player .main-info-cont {
  display: flex;
  flex: 3 0 0%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-width: 22vi;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 96%, rgba(0, 0, 0, 0));
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 96%, rgba(0, 0, 0, 0));
}

.list-player .main-info-cont span, .list-player div.lg-meta-cont span {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  white-space: nowrap;
}

.list-player div.meta-cont {
  font-size: small;
  opacity: var(--lp-text-op-2);
}

.list-player div.lg-meta-cont {
  display: none;
  flex: 2 0 0%;
  align-items: center;
  justify-content: flex-start;
  font-size: medium;
  opacity: var(--lp-text-op-2);
}

.list-player div.lg-meta-cont > div {
  height: fit-content;
}

.list-player div.lg-meta-cont > div.meta-artist {
  width: 18vi;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 96%, rgba(0, 0, 0, 0) 101%);
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 96%, rgba(0, 0, 0, 0) 101%);
}

.list-player div.lg-meta-cont > div.meta-album {
  width: 18vi;
  overflow: hidden;
}

/* THIS IS IMPORTANT!!!
This allows the list columns (title, artist, and album)
to adjust width according to parent container width.
A fixed width is necessary to force flex container not to
move the left edges of the individual 'cells'. Percent
doesnt work but relative units do. However, we need the
relative units to change according to parent container size
instead of viewport. Fallbacks are implemented above this
block */
@container info (min-width: 20px) {
  .list-player .main-info-cont {
    min-width: 28cqw!important;
  }
  .list-player div.lg-meta-cont > div.meta-artist {
    width: 26cqw;
  }

  .list-player div.lg-meta-cont > div.meta-album {
    width: 26cqw;
  }
}

.list-player div.duration-cont {
  display: flex;
  justify-content: flex-end;
  width: 5.3rem;
  margin-right: 0.6rem;
}

.list-player div.meta-artist, .list-player div.meta-album, .list-player div.meta-cont, .list-player div.title-cont {
  display: flex;
  align-items: center;
}
/* ---------------------------- */

/* HEADER --------------------- */

.list-player div.lh-children-cont {
  position: absolute;
  width: 100%;
  height: 100%;
}


.list-player div.controller-panel {
  --lp-backdrop-blur: blur(24px);
  backdrop-filter: var(--lp-backdrop-blur);
  background-color: rgb(0 0 0 / 0.1);
  position: absolute;
  bottom: 0px;
  left: auto;
  right: 0px;
  margin: 0.6rem;
  width: fit-content;
  height: fit-content;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 700ms;
}

.list-player button.mute-btn {
  order: 4;
  margin-right: 0.75rem;
  margin-left: 0.5rem;
}

.list-player button.mute-btn > .action-icon {
  width: 1.75rem;
  height: 1.75rem;
}

.list-player button.back-btn {
  order: 2;
}

.list-player button.forward-btn {
  order: 3;
  margin-right: 0.75rem
}

.list-player button.back-btn > .action-icon, .list-player button.forward-btn > .action-icon {
  width: 2.25rem;
  height: 2.25rem;
}

.list-player button.play-btn {
  order: 1;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.list-player button.play-btn > .action-icon {
  width: 3.5rem;
  height: 3.5rem;
}

.list-player div.controller-panel > .btn {
  opacity: 0.75;
}

.list-player div.controller-panel > .btn:hover {
  opacity: 1;
}



/* ---------------------------- */

/* INFOCARD ------------------- */
.list-player div.lt-info-card {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  height: 100%;
  min-height: fit-content;
  padding: 1rem/* 16px */;
  transition: padding 0.3s ease-in-out;
}

.list-player div.lt-info-panel {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 13.9rem/* 222.4px */;
  max-height: 30rem/* 480px */;
  padding-bottom: 5rem/* 80px */;
  padding-left: 0.75rem/* 12px */;
  padding-right: 0.75rem/* 12px */;
  padding-top: 0.5rem/* 8px */;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  animation: fadeIn 0.6s ease-in-out;
}

/* div.lt-info-title {

} */

.list-player h1.lt-info-title-h1 {
  font-size: 2rem;
  text-align: start;
}

.list-player div.lt-info-stats {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.25rem;
}

.list-player div.lt-info-stats > span, h1.lt-info-title-h1 > span {
  white-space: nowrap;
}

.list-player .lt-info-stats .text.pure {
  font-size: large;
  font-weight: 600;
  opacity: var(--lp-text-op-2);
}

.list-player div.lh-listinfocard-cont {
  padding: 0.64rem;
  container-type: size;
}

.list-player .lt-info-title h1, .list-player .lt-info-panel .lt-info-stats span.text.pure, .list-player .lt-info-panel .lt-info-stats span.text.number, .list-player .lt-info-panel .lt-info-stats span.cdot {
  transition: all 0.2s linear;
}

@container (max-height: 144px) and (max-width: 480px) {
  .list-player .lt-info-panel {
    opacity: 0;
  }
}

@container (min-height: 94px) and (max-height: 130px) {
  .list-player .lt-info-title h1 {
    font-size: x-large;
  }
  .list-player .lt-info-panel .lt-info-stats span.text.pure, .list-player .lt-info-panel .lt-info-stats span.text.number, .list-player .lt-info-panel .lt-info-stats span.cdot {
    font-size: small;
  }
}

@container (max-height: 192px) and (min-height: 130px) {
  .list-player .lt-info-title h1 {
    font-size: x-large;
  }
  .list-player .lt-info-panel .lt-info-stats span.text.pure, .list-player .lt-info-panel .lt-info-stats span.text.number, .list-player .lt-info-panel .lt-info-stats span.cdot {
    font-size: small;
  }
}

@container (max-width: 550px) {
  .list-player .lt-info-title h1 {
    font-size: x-large;
  }
  .list-player .lt-info-panel .lt-info-stats span.text.pure, .list-player .lt-info-panel .lt-info-stats span.text.number, .list-player .lt-info-panel .lt-info-stats span.cdot {
    font-size: small;
  }
}

@container (max-width: 550px) and (max-height: 200px) {
  .list-player .lt-info-title h1 {
    font-size: x-large;
  }
  .list-player .lt-info-panel .lt-info-stats span.text.pure, .list-player .lt-info-panel .lt-info-stats span.text.number, .list-player .lt-info-panel .lt-info-stats span.cdot {
    font-size: small;
  }
  .list-player div.lt-info-card {
    padding: 0;
  }
}

@container (max-width: 354px) {
  .list-player .lt-info-title h1 {
    font-size: large;
  }
  .list-player .lt-info-panel .lt-info-stats span.text.pure, .list-player .lt-info-panel .lt-info-stats span.text.number, .list-player .lt-info-panel .lt-info-stats span.cdot {
    font-size: xx-small;
  }
  .list-player div.lt-info-card {
    padding: 0;
  }
}

@container (max-height: 134px) or (max-width: 100px) {
  .list-player div.lt-info-card {
    padding: 0;
  }
}

@container (max-height: 94px) {
  .list-player .lt-info-title h1 {
    line-height: 140%;
    font-size: large;
  }
  .list-player .lt-info-panel .lt-info-stats span.text.pure, .list-player .lt-info-panel .lt-info-stats span.text.number, .list-player .lt-info-panel .lt-info-stats span.cdot {
    font-size: xx-small;
  }
  .list-player div.lt-info-card {
    padding: 0;
  }
}


/* BADGES ----------------------*/
.list-player span.badge {
  font-size: xx-small;
}

.list-player span.new {
  color: rgba(161, 204, 165, 0.8);
  padding: 0px 3px 0px 3px;
  border-radius: 2px;
  border: 2px solid rgba(161, 204, 165, 0.8);
}

.list-player span.explicit {
  color: rgba(255, 255, 255, 0.7);
  padding: 0px 3px 0px 3px;
  border-radius: 2px;
  border: 2px solid rgba(255, 255, 255, 0.7);
}
/* ---------------------------- */


@container list (min-width: 700px){
  .list-player div.lg-meta-cont {
      display: flex;
  }
  .list-player div.meta-cont {
      display: none!important;
  }
}

@media (prefers-color-scheme: dark) {
  .list-player div.track:hover, .list-player div.track.selected {
    background-color: rgba(0, 0, 0, 0.10);
  }
}

.list-player div.lt-info-img-cont {
  --lp-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  box-shadow: var(--lp-ring-offset-shadow, 0 0 #0000), var(--lp-ring-shadow, 0 0 #0000), var(--lp-shadow);
  height: fit-content;
  max-height: 100%;
  border-radius: 0.5rem;
  aspect-ratio: 1 / 1;
  overflow: hidden;
}

.list-player img.lt-info-img {
  height: 100%;
  width: 14rem;
  object-fit: cover;
}

.list-player div.lt-info-art-placeholder-cont {
  --lp-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-border-opacity: 1;
  box-shadow: var(--lp-ring-offset-shadow, 0 0 #0000), var(--lp-ring-shadow, 0 0 #0000), var(--lp-shadow);
  height: fit-content;
  max-height: 100%;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-radius: 0.5rem;
  border-width: 2px;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  opacity: 0.8;
}

.list-player div.lt-info-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 14rem;
  max-width: 100%;
  text-align: center;
  font-size: 1.875rem;
  line-height: 2.25rem;
  padding: 1rem;
  animation: fadeIn 1s ease-in-out;
  background: repeating-linear-gradient( 45deg, #22222266, #22222266 6px, #22222200 6px, #22222200 12px);
  container-name: art;
  container-type: inline-size;
}

.list-player div.lt-info-placeholder > span {
  font-size: 3.5rem;
  transition: font-size 0.2s linear;
}

@container art (max-width: 60px) {
  .list-player div.lt-info-placeholder > span {
    font-size: 2rem;
  }
}